import React, { Component } from "react"
import { withFirebase } from "./Firebase"
import { navigate, Link } from "gatsby"
import * as ROUTES from "../constants/routes"
import Export from "./export"

function getAverage(nums) {
  return nums.reduce((a, b) => a + b) / nums.length
}

class Results extends Component {
  constructor(props) {
    super(props)
    this.state = {
      overalScore: 0,
      submission: [
        {
          title: "Rationale",
          questions: [
            {
              question: "Question 01",
            },
            {
              question: "Question 02",
            },
            {
              question: "Question 03",
            },
            {
              question: "Question 04",
            },
          ],
        },
        {
          title: "Proposition",
          questions: [
            {
              question: "Question 01",
            },
            {
              question: "Question 02",
            },
            {
              question: "Question 03",
            },
            {
              question: "Question 04",
            },
          ],
        },
        {
          title: "Markets",
          questions: [
            {
              question: "Question 01",
            },
            {
              question: "Question 02",
            },
            {
              question: "Question 03",
            },
            {
              question: "Question 04",
            },
          ],
        },
        {
          title: "Business Model",
          questions: [
            {
              question: "Question 01",
            },
            {
              question: "Question 02",
            },
            {
              question: "Question 03",
            },
            {
              question: "Question 04",
            },
          ],
        },
        {
          title: "Partner Strategy",
          questions: [
            {
              question: "Question 01",
            },
            {
              question: "Question 02",
            },
            {
              question: "Question 03",
            },
            {
              question: "Question 04",
            },
          ],
        },
      ],
      chart: [
        {
          section: "",
          result: 0,
        },
        {
          section: "",
          result: 0,
        },
        {
          section: "",
          result: 0,
        },
        {
          section: "",
          result: 0,
        },
        {
          section: "",
          result: 0,
        },
      ],
      exploreCards: [
        {
          type: "Export Consultancy",
          title: "Rationales – International Strategy",
          link:
            "https://exportcatalyst.com/export-services/export-consultancy/internal-strategy/",
        },
        {
          type: "Market map",
          title: "Our Target Overseas Markets",
          link: "https://exportcatalyst.com/export-services/market-map/",
        },

        {
          type: "Export Consultancy",
          title: "Market selection and analysis",
          link:
            "https://exportcatalyst.com/export-services/export-consultancy/market-selection-analysis/",
        },
        {
          type: "",
          title: "Business Model - International Strategy",
          link: "",
        },
        {
          type: "",
          title: "Partner strategy – Export Collaborative",
          link: "",
        },
      ],
    }
  }

  componentDidMount() {
    const { user } = this.props
    const { submission } = user
    let chartResults = []
    let overalScore = 0

    console.log(this.props)

    for (let i in submission) {
      let answers = []
      let questions = submission[i].questions

      for (let e in questions) {
        answers.push(questions[e].answer)
      }

      let averageResult = {
        section: submission[i].title,
        result: getAverage(answers),
      }
      overalScore += getAverage(answers)
      chartResults.push(averageResult)
    }

    this.setState({
      chart: chartResults,
      overalScore: overalScore,
      loaded: true,
    })

    setTimeout(() => {
      this.setState({
        chart_load: true,
      })
    }, 300)
  }

  render() {
    // let results = []

    const authUser = this.props.user

    if (!authUser.completed) navigate(ROUTES.QUESTIONNAIRE)

    return (
      <>
        <section className="page-results">
          <div className="wrapper">
            <div className="export">
              <div className="intro-box">
                <div className="row">
                  <div className="col col-10">
                    <h1 className="heading--xxl thin">Results</h1>
                    <p className="body--l">
                      Congratulations on completing the survey and receiving
                      your score. Upon commencing their journey many
                      organisations will find themselves scoring at the lower
                      end of this scale due to the fact that a number of these
                      areas will not yet have been considered. However, these
                      six key strategic themes are the considerations that will
                      help you throughout your journey and the score helps you
                      to identify where you need to focus in order to increase
                      capability. For those organisations who are scoring
                      towards the higher end, you will be well positioned for
                      international work.
                    </p>
                    <h3 className="heading--m">
                      <strong>Next steps:</strong>
                    </h3>
                    <p className="body--l">
                      Now you have completed the survey and received your score,
                      a copy of the report will be emailed to you and will be
                      used in a follow up call with one of our Export Catalyst
                      Team. You will be taken through the background to
                      Healthcare UK, the process and expectations for the Export
                      Readiness Diagnostic Workshop, and capture any thoughts
                      and ideas that completing this survey may have triggered.
                    </p>
                    <p className="body--m">
                      We look forward to speaking with you.
                    </p>
                  </div>
                </div>
              </div>
              {this.state.loaded ? (
                <>
                  <ul className="chart_mobile">
                    {this.state.chart.map((result, result_id) => {
                      let percent = result.result.toFixed(0)
                      return (
                        <li key={result_id}>
                          <p>{result.section}</p>
                          <span>{percent}</span>
                        </li>
                      )
                    })}
                  </ul>

                  <div className="chart_container">
                    <div className="chart_labels">
                      {this.state.chart.map((result, result_id) => {
                        return (
                          <div className="chart_label" key={result_id}>
                            <p>{result.section}</p>
                            <div className="chart_label_triangle"></div>
                          </div>
                        )
                      })}
                    </div>

                    <div className="chart">
                      <p className="chart_axis_label body--s">
                        {" "}
                        less to more export ready
                      </p>
                      <div className="chart_splits">
                        <div className="chart_split"></div>
                        <div className="chart_split"></div>
                        <div className="chart_split"></div>
                        <div className="chart_split"></div>
                        <div className="chart_split"></div>
                      </div>
                      <span className="chart_line chart_line-0">
                        <p>0</p>
                      </span>
                      <span className="chart_line chart_line-1">
                        <p>1</p>
                      </span>
                      <span className="chart_line chart_line-2">
                        <p>2</p>
                      </span>
                      <span className="chart_line chart_line-3">
                        <p>3</p>
                      </span>
                      <span className="chart_line chart_line-4">
                        <p>4</p>
                      </span>
                      <span className="chart_line chart_line-5">
                        <p>5</p>
                      </span>
                      <span className="chart_line chart_line-6">
                        <p>6</p>
                      </span>
                      <span className="chart_line chart_line-7">
                        <p>7</p>
                      </span>
                      <span className="chart_line chart_line-8">
                        <p>8</p>
                      </span>
                      <span className="chart_line chart_line-9">
                        <p>9</p>
                      </span>
                      <span className="chart_line chart_line-10">
                        <p>10</p>
                      </span>

                      {this.state.chart.map((result, result_id) => {
                        let percent = 0

                        if (this.state.chart_load) {
                          percent = result.result.toFixed(0) * 10
                        }

                        return (
                          <div className="chart_bar" key={result_id}>
                            <div
                              className={
                                percent > 95
                                  ? "chart_bar_inner full"
                                  : "chart_bar_inner"
                              }
                            >
                              <div style={{ width: percent + "%" }}></div>
                              <span
                                className={
                                  this.state.chart_load ? "loaded" : ""
                                }
                              >
                                {result.result.toFixed(0)}
                              </span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </>
              ) : (
                <div className="results_loading">
                  <h2 className="heading--l">Loading your results</h2>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="total_banner">
          <div className="wrapper">
            <div className="row centered">
              <div className="col col-6">
                <h3 className="heading--l">
                  Want to see full results including all of your answers?
                </h3>
                <Link className="btn" to="/full-results">
                  <span>See Results</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="results_extra">
          <div className="wrapper">
            <div className="row centered">
              <div className="col col-10">
                <div className="improve_banner">
                  <div>
                    <h3 className="heading--l">Want to improve your score?</h3>
                    <p className="body--l">
                      Find out more about our export consultancy programmes
                      below.
                    </p>
                  </div>
                </div>
                <div className="explore_cards">
                  {this.state.exploreCards.map((card, key) => {
                    return (
                      <div className="row centered explore_card">
                        <div className="col col-6 explore_card__image"></div>
                        <div className="col col-6 explore_card__content">
                          <div className="explore_card__inner">
                            <div className="explore_card__tag">
                              <span className="icon-academy"></span>
                              <span className="tag_text">{card.type}</span>
                            </div>
                            <h3 className="heading--l">{card.title}</h3>
                            <a
                              href={card.link}
                              target="_blank"
                              rel="noreferrer"
                              className="link"
                            >
                              <span>Learn more</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default withFirebase(Results)
