import React, { Component } from "react"

class Export extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { results, submissions } = this.props

    return (
      <>
        <div className="export-pdf" id="export-pdf">
          <div className="chart_container">
            <div className="chart_labels">
              {results.map((result, result_id) => {
                return (
                  <div className="chart_label" key={result_id}>
                    <p>{result.section}</p>
                  </div>
                )
              })}
            </div>

            <div className="chart">
              <div className="chart_splits">
                <div className="chart_split"></div>
                <div className="chart_split"></div>
                <div className="chart_split"></div>
                <div className="chart_split"></div>
                <div className="chart_split"></div>
              </div>
              <span className="chart_line chart_line-0">
                <p>0</p>
              </span>
              <span className="chart_line chart_line-1">
                <p>1</p>
              </span>
              <span className="chart_line chart_line-2">
                <p>2</p>
              </span>
              <span className="chart_line chart_line-3">
                <p>3</p>
              </span>
              <span className="chart_line chart_line-4">
                <p>4</p>
              </span>
              <span className="chart_line chart_line-5">
                <p>5</p>
              </span>
              <span className="chart_line chart_line-6">
                <p>6</p>
              </span>
              <span className="chart_line chart_line-7">
                <p>7</p>
              </span>
              <span className="chart_line chart_line-8">
                <p>8</p>
              </span>
              <span className="chart_line chart_line-9">
                <p>9</p>
              </span>
              <span className="chart_line chart_line-10">
                <p>10</p>
              </span>
              {results.map((result, result_id) => {
                let percent = result.result.toFixed(0) * 10

                return (
                  <div className="chart_bar" key={result_id}>
                    <div className="chart_bar_inner">
                      <div style={{ width: percent + "%" }}></div>
                      <span>{result.result.toFixed(0)}</span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          {
            <div className="form_results">
              <ul>
                {Object.keys(submissions).map((submission, key) => {
                  let answers = []

                  for (let i in submissions[submission].questions) {
                    answers.push(submissions[submission].questions[i].answer)
                  }

                  function getAverage(nums) {
                    return nums.reduce((a, b) => a + b) / nums.length
                  }

                  let averageResult = {
                    section: submissions[submission].title,
                    result: getAverage(answers),
                  }

                  results.push(averageResult)

                  return (
                    <li key={key}>
                      <h3 className="heading--m">
                        {submissions[submission].title} : {getAverage(answers)}
                      </h3>
                      <br />
                      {submissions[submission] &&
                        submissions[submission].questions.map(
                          (answer, answer_id) => {
                            return (
                              <div key={answer_id}>
                                <p className="body--m">{answer.question}</p>
                                <p className="body--m">
                                  <strong>{answer.answer}</strong>
                                </p>
                              </div>
                            )
                          }
                        )}
                    </li>
                  )
                })}
              </ul>
            </div>
          }
        </div>
      </>
    )
  }
}

export default Export
