import React from "react"
import { compose } from "recompose"
import Layout from "../components/layout"
import Results from "../components/results"
import { AuthUserContext } from "../components/Session"
import { withAuthorization } from "../components/Session"

const condition = authUser => !!authUser

const ResultsPageBase = () => (
  <AuthUserContext.Consumer>
    {authUser => {
      return <Results user={authUser} />
    }}
  </AuthUserContext.Consumer>
)

const ResultsPage = compose(withAuthorization(condition))(ResultsPageBase)

export default () => (
  <Layout activePage="results" dark="true">
    <ResultsPage />
  </Layout>
)
